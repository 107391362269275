import {FunctionComponent, PropsWithChildren, ReactElement} from 'react';
import {
    Container,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    LinearProgress,
} from '@material-ui/core';
import {PDFViewer} from '@react-pdf/renderer';
import {Paper, Report} from '../../organisms';

import {GradesViewProps} from './types';

const viewLoader = (props: PropsWithChildren<GradesViewProps>): ReactElement => {
    return (
        <Dialog
            open={props.dialog}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
        >
            <DialogTitle id="dialog-title">Por favor aguarde...</DialogTitle>
            <DialogContent>
                <DialogContentText id="dialog-description">
                    {props.message}
                </DialogContentText>
            </DialogContent>
            <LinearProgress/>
        </Dialog>
    );
};

const viewPDF = (document: ReactElement): ReactElement => {
    return (
        <PDFViewer width={'96%'} height={'600'} style={{marginLeft: '2%', marginRight: '2%', marginTop: '20px'}}>
            {
                document
            }
        </PDFViewer>
    );
};

const generateReport = (props: PropsWithChildren<GradesViewProps>): ReactElement | undefined => {
    if (!props.dialog && props.grades?.avaliacaoAlunos && props.parameters && props.periods && props.domains) {
        return (
            <Report grades={props.grades}
                    periods={props.periods}
                    parameters={props.parameters}
                    domains={props.domains}/>
        );
    }
    return undefined;
};

const GradesView: FunctionComponent<GradesViewProps> = (props: PropsWithChildren<GradesViewProps>) => {
    const report: ReactElement | undefined = props.grades ? generateReport(props) : undefined;
    return (
        <Container maxWidth="lg">
            <Paper onReturn={props.onClose} title={'Grelha de avaliação'} paperHeaderColor={'primary'}>
                <Grid item xs={12}>
                    {
                        !report
                            ? viewLoader(props)
                            : viewPDF(report)
                    }
                </Grid>
            </Paper>
        </Container>
    );
};

export default GradesView;
